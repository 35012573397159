import React from 'react'
import DOMPurify from 'dompurify';

const OrderItem = ({ order }) => {
    return (
        <div className=' grid grid-cols-8 gap-4 h-full rounded-2xl p-4 border border-black/25'>
            <div className='col-span-8 flex items-center justify-between w-full' onClick={() => console.log(order.items)}>
                <div>
                    {new Date(order.createdAt).toLocaleDateString()}{" "}
                    {new Date(order.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                </div>
                <p className='text-[#EF2D2D] font-semibold text-base md:text-lg lg:text-xl'>
                    ₹{order.totalPrice}
                </p>
            </div>
            {order.items.map((item, index) => (
                <div key={index} className='grid grid-cols-9 col-span-8 gap-4 h-full'>
                    <div className="h-full col-span-2 overflow-hidden">
                        {item?.product?.image[0] ? (
                            <img
                                src={`${process.env.REACT_APP_BASE_URL}/${item?.product?.image[0]}`}
                                alt="Product"
                                className="h-full w-full object-cover"
                                crossOrigin="anonymous"
                                onClick={() => console.log(order)}
                            />
                        ) : (
                            <div className="text-center text-gray-500">Image Not Available</div>
                        )}
                    </div>
                    {item?.product?.name ? (
                        <div className='flex flex-col justify-between w-full col-span-7 h-full py-4'>
                            <div className='text-sm md:text-base lg:text-lg'>
                                {item.product.name || ""}
                            </div>
                            {/* <div
                                className="text-sm lg:text-base"
                                dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(item.product.description || ''),
                                }}
                            ></div> */}
                            <div className='flex items-center pt-2 gap-1'>
                                <p className='text-[#EF2D2D] font-semibold text-base md:text-lg lg:text-xl'>
                                    ₹{item.price * item.quantity}
                                </p>
                                <p className='text-[#848484] text-sm md:text-base lg:text-lg line-through'>
                                    ₹{item.product.variation[0].price}
                                </p>
                                <p className='text-[#43B678] text-sm md:text-base lg:text-lg'>
                                    {`${item.product.variation[0].discount}% off`}
                                </p>
                            </div>
                            <div className='flex items-center gap-2 p-1 text-[#848484]'>
                                <div className='bg-[#E0F1F6] rounded-lg flex items-center gap-2 p-1'>
                                    <div>Qty</div>
                                    <div className='flex items-center gap-2'>
                                        <div>{item.quantity}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="text-center text-gray-500 col-span-7">something went wrong...</div>
                    )}
                </div>
            ))}

        </div>
    )
}

export default OrderItem
