import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addToOrder, fetchOrderItems } from '../apiEndPoint';

// Thunks for async actions
export const fetchOrdersThunk = createAsyncThunk(
    'orders/fetchOrders',
    async (_, { rejectWithValue }) => {
        try {
            const orders = await fetchOrderItems();
            // Filter out orders where product is null
            const filteredOrders = orders.filter(order =>
                order.items.every(item => item.product !== null)
            );
            return filteredOrders;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

export const addOrderThunk = createAsyncThunk(
    'orders/addOrder',
    async ({ items, totalPrice, address, paymentMethod }, { rejectWithValue }) => {
        try {
            const newOrder = await addToOrder(items, totalPrice, address, paymentMethod);
            return newOrder;
        } catch (error) {
            return rejectWithValue(error.response?.data || error.message);
        }
    }
);

// Initial state
const initialState = {
    orders: [],
    isLoading: false,
    error: null,
};

// Slice
const orderSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        clearOrders: (state) => {
            state.orders = [];
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch Orders
            .addCase(fetchOrdersThunk.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchOrdersThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.orders = action.payload;
            })
            .addCase(fetchOrdersThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })
            // Add Order
            .addCase(addOrderThunk.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addOrderThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                state.orders.push(action.payload);
            })
            .addCase(addOrderThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            });
    },
});

// Actions and Reducer
export const { clearOrders } = orderSlice.actions;
export default orderSlice.reducer;
