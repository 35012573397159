const myProfileString = {
    title: "My Profile",
    desc: "Thank you for being a valuable customer. You can modify your details and can check other details here.",
    buttons: [
        "Personal Details",
        "My Orders",
        "My Wishlist",
        "Get Quotes",
        "FAQs",
        "Help",
        "Log Out",
    ],
    orderCategory: [
        "All", "Current Orders", "Completed Orders"
    ],
    helpHistory: [
        "Order Inquiry History", "Inquiry History"
    ],
    FAQs: [
        { question: "How do I place an order?", answer: "To place an order, browse our catalog, add items to your cart, and proceed to checkout. Follow the prompts to enter your shipping and payment information." },
        { question: "Do I need a prescription to order medications?", answer: "" },
        { question: "How long will it take to receive my order?", answer: "" },
        { question: "Can I return or exchange a product?", answer: "" },
        { question: "Is my personal and payment information secure?", answer: "" },
    ],
    injections: {
        title: "Blood Pressure Monitor | Doctor’s choice | No. 1 Selling...",
        price: "₹5599",
        crossPrice: '₹6599',
        off: "10% off",
        addToCartBtn: "Add to Cart",
    },
}
export default myProfileString