// src/reduxStore/cartSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { axiosInstance } from '../apiEndPoint';

// Fetch cart items
export const fetchCartItems = createAsyncThunk('cart/fetchCartItems', async () => {
    const response = await axiosInstance.get('cart');
    return response.data.data; // assuming the data you need is in `data`
});

// Add cart item
export const addCartItem = createAsyncThunk(
    'cart/addCartItem',
    async ({ productId, quantity }, { getState, rejectWithValue, dispatch }) => {
        try {
            console.log('Adding item:', { productId, quantity });

            // Fetch the current state to check existing items
            const state = getState();
            console.log('Current cart state:', state.cart.items);

            const response = await axiosInstance.post('cart', { productId, quantity });
            console.log('Response from addCartItem:', response.data);

            dispatch(fetchCartItems()); // Fetch latest cart items after adding an item
            return response.data.data;
        } catch (error) {
            console.error('Error adding item to cart:', error.response?.data || error.message);
            return rejectWithValue('Error adding item to cart');
        }
    }
);

// Update cart item quantity
export const updateCartItemQuantity = createAsyncThunk(
    'cart/updateCartItemQuantity',
    async ({ id, quantity }, { rejectWithValue, dispatch }) => {
        try {
            await axiosInstance.patch(`cart/${id}`, { quantity });
            dispatch(fetchCartItems()); // Fetch updated cart items
        } catch (error) {
            return rejectWithValue('Error updating item quantity');
        }
    }
);

// Delete cart item
export const deleteCartItem = createAsyncThunk(
    'cart/deleteCartItem',
    async (id, { rejectWithValue, dispatch }) => {
        try {
            await axiosInstance.delete(`cart/${id}`);
            dispatch(fetchCartItems()); // Fetch latest cart items after deletion
            return id;
        } catch (error) {
            return rejectWithValue('Error deleting item from cart');
        }
    }
);

const cartSlice = createSlice({
    name: 'cart',
    initialState: {
        items: [],
        quantity: 1,
        status: 'idle',
        error: null, // To store error messages silently
    },
    reducers: {
        incrementQuantity: (state, action) => {
            const { productId, quantity } = action.payload; // Pass productId and the current quantity
            state.items = state.items.map((item) =>
                item.productId === productId ? { ...item, quantity: quantity + 1 } : item
            );
        },
        decrementQuantity: (state, action) => {
            const { productId, quantity } = action.payload; // Pass productId and the current quantity
            if (quantity > 1) {
                state.items = state.items.map((item) =>
                    item.productId === productId ? { ...item, quantity: quantity - 1 } : item
                );
            }
        },
    },

    extraReducers: (builder) => {
        builder
            // Fetch cart items
            .addCase(fetchCartItems.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchCartItems.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload;
            })
            .addCase(fetchCartItems.rejected, (state, action) => {
                state.status = 'failed';
                // Silently handle the fetch error
                state.error = 'Error fetching cart items';
            })
            // Add cart item
            .addCase(addCartItem.fulfilled, (state, action) => {
                console.log('Action payload:', action.payload);
                console.log('Previous state:', state.items);

                // Ensure state.items is an array
                if (!Array.isArray(state.items)) {
                    console.error('state.items is not an array:', state.items);
                    state.items = []; // Reset it to an array if needed
                }

                const existingItem = state.items.find(item => item.productId === action.payload.productId);
                if (existingItem) {
                    existingItem.quantity += action.payload.quantity;
                } else {
                    state.items.push(action.payload);
                }

                console.log('Updated state:', state.items);
            })
            .addCase(addCartItem.rejected, (state, action) => {
                console.error('Add cart item error:', action.payload);

                // Handle the error gracefully
                state.error = action.payload || 'Unexpected error occurred';
            })
            // Delete cart item
            .addCase(deleteCartItem.fulfilled, (state, action) => {
                if (Array.isArray(state.items)) {
                    state.items = state.items.filter(item => item.productId?._id !== action.payload);
                }
            })
            .addCase(deleteCartItem.rejected, (state, action) => {
                console.error('Delete cart item error:', action.payload);
                state.error = null;
            });
    },
});

export const { incrementQuantity, decrementQuantity } = cartSlice.actions;
export default cartSlice.reducer;
