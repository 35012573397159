import React from 'react'
import profile from './../../assets/signInProfile.png'
import { Link, useLocation } from 'react-router-dom'
import backButton from './../../assets/Backbutton2.png'
import AdminQuotationCard from './AdminQuotationCard'

const InquiryDetails = () => {
    const location = useLocation();
    const { title } = location.state || {}; // Fallback if state is undefined

    return (
        <section className="flex justify-center font-poppins bg-[#F3F4F4] h-screen">
            <div className='container px-6 py-12'>
                <div className='w-full'>
                    <div className='flex items-center gap-4 py-4'>
                        <button className='text-[#848484] '>
                            <Link to="/" className='flex items-center gap-2'>
                                <img src={backButton} alt="" /> Home
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            <Link to="/myProfile">
                                &gt; My Profile
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            <Link to="/myProfile">
                                &gt; Help
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            <Link to="/myProfile">
                                &gt; Inquiry History
                            </Link>
                        </button>
                        <button className='text-[#848484] flex items-center gap-2'>
                            &gt; Details
                        </button>
                    </div>
                    <div className='grid grid-cols-5 gap-4'>
                        <div className='col-span-5  p-4 bg-white'>
                            <div className='flex items-start px-4'>
                                <div className=' flex items-center justify-center h-full'>
                                    <img src={title.imgSrc} alt="" className='w-60 h-40 bg-cover object-cover' onClick={() => console.log(title)} />
                                </div>
                                <div className='flex justify-between flex-col px-4 '>
                                    <div className='text-[#848484]'>Added on: 04 August 2024</div>
                                    <div className='font-semibold text-lg md:text-xl xl:text-2xl'>{title.title}</div>
                                </div>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 p-4'>
                                <AdminQuotationCard price={1000} />
                                <AdminQuotationCard price={1500} disable={true} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InquiryDetails
