import React, { useState } from 'react'
import infoIconBlack from './../../assets/infoIconBlack.png'
import CreateNewInquiry from './CreateNewInquiry';

const AdminQuotationCard = ({ title, price, disable }) => {
    const [isFormVisible, setFormVisible] = useState(false);
    // Function to open the inquiry form
    const openForm = () => {
        setFormVisible(true);
    };

    // Function to close the inquiry form
    const closeForm = () => {
        setFormVisible(false);
    };
    return (
        <>
            <div className={`col-span-1 rounded-2xl h-min ${disable ? "bg-[#8484842E]" : "bg-[#ECF2F3B2]"}  p-4`}>
                <div className='flex items-center justify-between'>
                    <div>Admin Quotation</div>
                    <div className={`${disable ? "text-[#848484]" : "text-[#EF2D2D]"}`}>{price}</div>
                </div>
                <div className='text-right flex items-center gap-2 pt-8'>
                    <button className={`border ${disable ? "border-[#848484]" : "border-[#43B678]"} p-1 px-2 rounded-full ${disable ? "text-[#848484]" : "text-[#43B678]"} ${disable ? "hover:bg-[#848484]" : "hover:bg-[#43B678]"} hover:text-white`}>Accept</button>
                    <button className={`border ${disable ? "border-[#848484]" : "border-[#EF2D2D]"} p-1 px-2 rounded-full ${disable ? "text-[#848484]" : "text-[#EF2D2D]"} ${disable ? "hover:bg-[#848484]" : "hover:bg-[#EF2D2D]"} hover:text-white`} onClick={openForm}>Decline</button>
                </div>
                {disable && (
                    <div className='flex items-center gap-1 pt-4'>
                        <img src={infoIconBlack} alt="" className='size-4' />
                        <div className='text-[#848484]'>The price is not suitable.</div>
                    </div>
                )}
                {isFormVisible && (
                    <CreateNewInquiry onClose={closeForm} title={"Write a Reason"} disc={"Please provide a brief reason for declining the request."} />
                )}
            </div >
        </>
    )
}

export default AdminQuotationCard
