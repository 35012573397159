import { createSlice } from '@reduxjs/toolkit';
import Swal from 'sweetalert2';

// Helper functions to interact with localStorage
const loadCartFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('cartItem')) || [];
};

const saveCartToLocalStorage = (cartItems) => {
    localStorage.setItem('cartItem', JSON.stringify(cartItems));
};

// Initial state
const initialState = {
    cartItems: loadCartFromLocalStorage(),
};

const cartLocalSlice = createSlice({
    name: 'cartLocal',
    initialState,
    reducers: {
        addToCart: (state, action) => {
            const newItem = action.payload;

            // Check for duplicates
            const existingItem = state.cartItems.find(
                (item) => item.productId === newItem.productId
            );

            if (existingItem) {
                // Show SweetAlert if item is already in the cart
                Swal.fire({
                    title: 'Item already in cart!',
                    text: 'This item is already in your cart.',
                    icon: 'warning',
                    confirmButtonText: 'OK'
                });
            } else {
                state.cartItems.push(newItem);
                saveCartToLocalStorage(state.cartItems);
            }
        },
        removeFromCart: (state, action) => {
            const productId = action.payload;

            // Filter out the item with the given productId
            state.cartItems = state.cartItems.filter(
                (item) => item.productId !== productId
            );
            saveCartToLocalStorage(state.cartItems);
        },
        clearCart: (state) => {
            state.cartItems = [];
            saveCartToLocalStorage(state.cartItems);
        },
    },
});

export const { addToCart, removeFromCart, clearCart } = cartLocalSlice.actions;
export default cartLocalSlice.reducer;
