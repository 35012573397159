import axios from "axios";

const axiosApi = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
});
const SHIPROCKET_API_BASE = `${process.env.SHIPROCKET_API_BASE}`;

export const axiosInstance = axiosApi;

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("SignIn token");
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        // //console.log("Response:", response);
        return response;
    },
    (error) => {
        //console.log("Error response:", error.response);
        if (error.response && error.response.status === 401) {
            //console.log("Unauthorized, redirecting to login...");
            localStorage.clear();
            // localStorage.clear();
            // window.location.href = "/login";
            // window.location.reload();
        }
        return Promise.reject(error);
    }
);

export const registerUser = (formData) => async (dispatch) => {
    try {
        const response = await axiosInstance.post(`users/register`, formData);

        // Dispatch actions based on the response
        dispatch({ type: "REGISTER_SUCCESS", payload: response.data });

        return response.data;
    } catch (error) {
        console.error("Registration failed:", error);
        dispatch({ type: "REGISTER_FAILURE", payload: error.message });
        throw error;
    }
};

// Function to send OTP to the user's email
export const sendOtp = (email) => async (dispatch) => {
    try {
        const response = await axiosInstance.post(`users/sendotp`, { email });

        // Dispatch success action if needed
        dispatch({ type: "SEND_OTP_SUCCESS", payload: response.data });

        return response.data;
    } catch (error) {
        console.error("Failed to send OTP:", error);

        // Dispatch failure action if needed
        dispatch({ type: "SEND_OTP_FAILURE", payload: error.message });

        throw error;
    }
};
export const verifyOtp = (otpData) => async (dispatch) => {
    try {
        const response = await axiosInstance.post(`users/verifyotp`, otpData);

        // Dispatch success action if needed
        dispatch({ type: "VERIFY_OTP_SUCCESS", payload: response.data });

        return response.data;
    } catch (error) {
        console.error("Failed to verify OTP:", error);

        // Dispatch failure action if needed
        dispatch({ type: "VERIFY_OTP_FAILURE", payload: error.message });

        throw error;
    }
};

// shiprocket tocken generation 
export const generateToken = async (email, password) => {
    try {
        const response = await axios.post(`${SHIPROCKET_API_BASE}/auth/login`, {
            email,    // Replace with the API user's email
            password, // Replace with the API user's password
        });

        // The token will be in the response
        console.log('Token Generated:', response.data.token);
        localStorage.setItem("Shiprocket token", response.data.token);
        return response.data.token;
    } catch (error) {
        console.error('Error Generating Token:', error.response?.data || error.message);
    }
};

const createOrder = async (token, orderData) => {
    try {
        const response = await axios.post(
            'https://apiv2.shiprocket.in/v1/external/orders/create/adhoc',
            orderData,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        console.log('Order Created:', response.data);
    } catch (error) {
        console.error('Error creating order:', error.response?.data || error.message);
    }
};


// Action creator for logging in the user
export const loginUser = (email, password) => async (dispatch) => {
    try {
        const response = await axiosInstance.post("users/login", {
            email,
            password,
        });

        // Dispatch an action for successful login
        dispatch({ type: "LOGIN_SUCCESS", payload: response.data });

        return response.data;
    } catch (error) {
        // Dispatch an error action if login fails
        dispatch({ type: "LOGIN_ERROR", payload: error.message });

        console.error("Login failed:", error);
        throw error;
    }
};

export const fetchUserProfile = async (id) => {
    try {
        const response = await axiosInstance.get(`users/profile`, id);
        //console.log(response.data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const fetchCategories = async () => {
    try {
        const response = await axiosInstance.get(`categories/all`);
        return response.data.data; // Return only response data, not the entire response
    } catch (error) {
        throw error;
    }
};

export const fetchSubCategories = async () => {
    try {
        const response = await axiosInstance.get(`subcategories/all`);
        //console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
export const fetchProducts = async () => {
    try {
        const response = await axiosInstance.get(`products/all`);
        //console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
export const fetchProductBySubCategory = async (id) => {
    try {
        const response = await axiosInstance.get(`products/subcategory/${id}`);
        return response.data.data;
    } catch (error) {
        console.error("Failed to fetch product by subcategory", error);
        throw error;
    }
};
export const fetchAllCustomerReviews = async () => {
    try {
        const response = await axiosInstance.get(`customer-reviews`);
        //console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
export const fetchAwardsAndRecognition = async () => {
    try {
        const response = await axiosInstance.get(`awards`);
        //console.log(response.data.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
export const contactUsForm = async (contactData) => {
    try {
        const response = await axiosInstance.post("/contactUs", contactData);
        return response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code outside of 2xx
            console.error("Error response:", error.response);
            console.error("Error status:", error.response.status);
            console.error("Error data:", error.response.data);
        } else if (error.request) {
            // The request was made but no response was received
            console.error("No response received:", error.request);
        } else {
            // Something went wrong in setting up the request
            console.error("Request setup error:", error.message);
        }
        throw error; // Rethrow the error so it can be handled in the calling component
    }
};
// search API
export const searchAll = async (text) => {
    try {
        const response = await axiosInstance.get(`search?q=${text}`);
        return response.data.data;
    } catch (error) {
        console.error("Failed to fetch product by subcategory", error);
        throw error;
    }
};
// big offers
export const fetchBigOffer = async () => {
    try {
        const response = await axiosInstance.get(`products/filter/bigoffer`);
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
// refurbished products
export const fetchRefurbishedProducts = async () => {
    try {
        const response = await axiosInstance.get(`products/filter/refurbished`);
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
// new arrivals
export const fetchNewArrivals = async () => {
    try {
        const response = await axiosInstance.get(`products/filter/newarrivals`);
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
//most selling products
export const fetchMostSelling = async () => {
    try {
        const response = await axiosInstance.get(`products/filter/mostselling`);
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
// get related products
export const fetchRelatedProducts = async (id) => {
    try {
        const response = await axiosInstance.get(`products/related/${id}`);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
// cart items
export const fetchCartItemsData = async () => {
    try {
        const response = await axiosInstance.get(`cart`);
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
//Add to cart
export const addToCart = async ({ productId, quantity }) => {
    try {
        const response = await axiosInstance.post('cart', { productId, quantity });
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
//Delete from cart
export const deleteFromCart = async (id) => {
    try {
        const response = await axiosInstance.delete(`cart/${id}`);
        return response.data.data;
    } catch (error) {
        console.error("Failed to delete product from cart", error);
        throw error;
    }
};
// WISHLIST items
export const fetchWishListItems = async () => {
    try {
        const response = await axiosInstance.get(`wishlists/all`);
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
//Add to wishlist
export const addToWishlist = async ({ user, products }) => {
    try {
        const response = await axiosInstance.post('wishlists', { user, products });
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
//Delete from wishlist
export const deleteFromWishlist = async (id) => {
    try {
        const response = await axiosInstance.delete(`wishlists/${id}`);
        return response.data.data;
    } catch (error) {
        console.error("Failed to delete product from wishlist", error);
        throw error;
    }
};
//Add to get quotes
export const addToGetQuote = async ({ product, userMessage }) => {
    try {
        const response = await axiosInstance.post('quote', { product, userMessage });
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
};
// get quotes items
export const fetchGetQuotes = async () => {
    try {
        const response = await axiosInstance.get(`quote`);
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
// order items
export const fetchOrderItems = async () => {
    try {
        const response = await axiosInstance.get(`orders`);
        //console.log(response.data);
        return response.data.data;
    } catch (error) {
        throw error;
    }
}
//Add order
export const addToOrder = async (items, totalPrice, address, paymentMethod) => {
    try {
        const payload = {
            items,
            totalPrice,
            address,
            paymentMethod
        };

        const response = await axiosInstance.post('orders', payload);
        // Uncomment the next line to debug the response if needed
        console.log(response.data);
        return response.data.data;
    } catch (error) {
        console.error('Error adding to order:', error.response?.data || error.message);
        throw error;
    }
};